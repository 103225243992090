@use 'colors';
@use 'variables';

/* react-bootstrap-typeahead CSS overrides */
.rbt {
  .rbt-loader {
    display: none;
  }

  .rbt-token {
    color: colors.$primary;
    background-color: rgba(colors.$primary, .15);
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 3px;

    &.rbt-token-active {
      background-color: colors.$primary;
      color: colors.$white;
    }
  }

  .rbt-input-multi {
    position: relative;
    z-index: 3;
  }

  .rbt-input-multi.focus {
    border-color: variables.$input-focus-border-color;
  }
}

.autocomplete-menu-open {
  .rbt-input-multi, .rbt-input-multi:focus, .rbt-input-multi.focus {
    box-shadow: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    border-color: variables.$input-focus-border-color !important;
  }
}
