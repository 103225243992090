@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src:
    local('Nunito ExtraLight'),
    local('Nunito-ExtraLight'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA-seUYevI.woff2 */
    url('/fonts/Nunito_200.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA-seUYevO.woff */
    url('/fonts/Nunito_200.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA-seUYevM.eot */
    url('/fonts/Nunito_200.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=XRXW3I6Li01BKofA-seUYevP&skey=8ad0032e0b847aa6&v=v14#Nunito */
    url('/fonts/Nunito_200.svg#Nunito') format('svg'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA-seUYevN.ttf */
    url('/fonts/Nunito_200.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src:
    local('Nunito Light'),
    local('Nunito-Light'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAnsSUYevI.woff2 */
    url('/fonts/Nunito_300.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAnsSUYevO.woff */
    url('/fonts/Nunito_300.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAnsSUYevM.eot */
    url('/fonts/Nunito_300.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=XRXW3I6Li01BKofAnsSUYevP&skey=327ea4351017b269&v=v14#Nunito */
    url('/fonts/Nunito_300.svg#Nunito') format('svg'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAnsSUYevN.ttf */
    url('/fonts/Nunito_300.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src:
    local('Nunito Regular'),
    local('Nunito-Regular'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXV3I6Li01BKofINeaB.woff2 */
    url('/fonts/Nunito_400.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXV3I6Li01BKofINeaH.woff */
    url('/fonts/Nunito_400.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXV3I6Li01BKofINeaF.eot */
    url('/fonts/Nunito_400.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=XRXV3I6Li01BKofINeaG&skey=27bb6aa8eea8a5e7&v=v14#Nunito */
    url('/fonts/Nunito_400.svg#Nunito') format('svg'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXV3I6Li01BKofINeaE.ttf */
    url('/fonts/Nunito_400.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src:
    local('Nunito Italic'),
    local('Nunito-Italic'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXX3I6Li01BKofIMNaDRs4.woff2 */
    url('/fonts/Nunito_400i.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXX3I6Li01BKofIMNaDRsg.woff */
    url('/fonts/Nunito_400i.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXX3I6Li01BKofIMNaDRso.eot */
    url('/fonts/Nunito_400i.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=XRXX3I6Li01BKofIMNaDRsk&skey=75768d03a8bfc23a&v=v14#Nunito */
    url('/fonts/Nunito_400i.svg#Nunito') format('svg'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXX3I6Li01BKofIMNaDRss.ttf */
    url('/fonts/Nunito_400i.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src:
    local('Nunito SemiBold'),
    local('Nunito-SemiBold'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA6sKUYevI.woff2 */
    url('/fonts/Nunito_600.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA6sKUYevO.woff */
    url('/fonts/Nunito_600.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA6sKUYevM.eot */
    url('/fonts/Nunito_600.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=XRXW3I6Li01BKofA6sKUYevP&skey=52e177ab83d749aa&v=v14#Nunito */
    url('/fonts/Nunito_600.svg#Nunito') format('svg'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA6sKUYevN.ttf */
    url('/fonts/Nunito_600.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src:
    local('Nunito Bold'),
    local('Nunito-Bold'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAjsOUYevI.woff2 */
    url('/fonts/Nunito_700.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAjsOUYevO.woff */
    url('/fonts/Nunito_700.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAjsOUYevM.eot */
    url('/fonts/Nunito_700.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=XRXW3I6Li01BKofAjsOUYevP&skey=388548fee26cf4d3&v=v14#Nunito */
    url('/fonts/Nunito_700.svg#Nunito') format('svg'),
      /* from https://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAjsOUYevN.ttf */
    url('/fonts/Nunito_700.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2 */
    url('/fonts/Montserrat_300.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD-A.woff */
    url('/fonts/Montserrat_300.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD-g.eot */
    url('/fonts/Montserrat_300.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_cJD3gnD-Q&skey=1ce67628c9aa416a&v=v15#Montserrat */
    url('/fonts/Montserrat_300.svg#Montserrat') format('svg'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD-w.ttf */
    url('/fonts/Montserrat_300.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2 */
    url('/fonts/Montserrat_400.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WlhzQ.woff */
    url('/fonts/Montserrat_400.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzw.eot */
    url('/fonts/Montserrat_400.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=JTUSjIg1_i6t8kCHKm459WlhzA&skey=7bc19f711c0de8f&v=v15#Montserrat */
    url('/fonts/Montserrat_400.svg#Montserrat') format('svg'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf */
    url('/fonts/Montserrat_400.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2 */
    url('/fonts/Montserrat_500.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD-A.woff */
    url('/fonts/Montserrat_500.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD-g.eot */
    url('/fonts/Montserrat_500.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_ZpC3gnD-Q&skey=7675f1290281829e&v=v15#Montserrat */
    url('/fonts/Montserrat_500.svg#Montserrat') format('svg'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD-w.ttf */
    url('/fonts/Montserrat_500.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2 */
    url('/fonts/Montserrat_600.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD-A.woff */
    url('/fonts/Montserrat_600.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD-g.eot */
    url('/fonts/Montserrat_600.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_bZF3gnD-Q&skey=72ae044fab0d9adc&v=v15#Montserrat */
    url('/fonts/Montserrat_600.svg#Montserrat') format('svg'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD-w.ttf */
    url('/fonts/Montserrat_600.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2 */
    url('/fonts/Montserrat_700.woff2') format('woff2'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD-A.woff */
    url('/fonts/Montserrat_700.woff') format('woff'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD-g.eot */
    url('/fonts/Montserrat_700.eot?#iefix') format('embedded-opentype'),
      /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_dJE3gnD-Q&skey=11a939c399e8c9fe&v=v15#Montserrat */
    url('/fonts/Montserrat_700.svg#Montserrat') format('svg'),
      /* from https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf */
    url('/fonts/Montserrat_700.ttf') format('truetype');
}

