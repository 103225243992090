@use 'colors' as c;
@use 'variables' as v;

html,
body {
  height: 100%;
  background: rgba(c.$base, .75);
  font-size: v.$font-size-base;
}

#__next, .app-wrapper {
  height: 100%;
}

label:not(.custom-control-label) {
  font-weight: 600;
}

.input-group {
  .input-group-prepend, .input-group-append {
    .dropdown {
      .btn {
        font-size: v.$input-font-size;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  #__next {
    .form-group {
      margin-bottom: v.$spacer * 1.5;
    }

    .rmd-notification-stack {
      top: 0;
      left: 0;
      right: 0;

      .rmd-notification {
        width: 100%;
        transform: translate3d(0, -100%, 0);
        border-radius: 0;

        &.open {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

@media print {
  html {
    height: 99%;
    zoom: 1;
    -webkit-print-color-adjust: exact;
    background-color: white;
  }

  body {
    height: 99%;
    background-color: white;
  }
}
