@use 'colors' as c;
@use 'variables' as v;

@function rgb($hexcolor){
  $red:red($hexcolor);
  $green:green($hexcolor);
  $blue:blue($hexcolor);
  $alpha:alpha($hexcolor);
  @return unquote("rgb(#{$red},#{$green},#{$blue})");
}

:root {
  --border-radius: #{v.$border-radius};
  --font-size-base: #{v.$font-size-base};
  --spacer: #{v.$spacer};
  --box-shadow: #{v.$box-shadow};
  --button-transition: #{v.$button-transition};

  --input-placeholder-color: #{v.$input-placeholder-color};

  --side-menu-zIndex: #{v.$side-menu-zIndex};
  --side-menu-backdrop-zIndex: #{v.$side-menu-backdrop-zIndex};
  --side-menu-header-height: #{v.$side-menu-header-height};
  --side-menu-content-height: #{v.$side-menu-content-height};
  --side-menu-action-buttons-height: #{v.$side-menu-action-buttons-height};

  --modal-zIndex: #{v.$modal-zIndex};
  --modal-backdrop-zIndex: #{v.$modal-backdrop-zIndex};
  --modal-default-width: #{v.$modal-default-width};
  --modal-small-width: #{v.$modal-small-width};
  --modal-large-width: #{v.$modal-large-width};
  --modal-padding: #{v.$modal-padding};
  --modal-action-buttons-height: #{v.$modal-action-buttons-height};
  --modal-header-height: #{v.$modal-header-height};

  --notification-container-width: #{v.$notification-container-width};
  --notification-container-zIndex: #{v.$notification-container-zIndex};
  --notification-container-top-offset: #{v.$notification-container-top-offset};
  --notification-container-right-offset: #{v.$notification-container-right-offset};
  --notification-item-spacing: #{v.$notification-item-spacing};
  --notification-item-background-color: #{v.$notification-item-background-color};
  --notification-item-initial-transform-x: #{v.$notification-item-initial-transform-x};
  --notification-item-text-color: #{v.$notification-item-text-color};
  --notification-item-default-color: #{v.$notification-item-default-color};
  --notification-item-dismiss-btn-color: #{v.$notification-item-dismiss-btn-color};
  --notification-item-timestamp-color: #{v.$notification-item-timestamp-color};
  --notification-item-success-color: #{v.$notification-item-success-color};
  --notification-item-info-color: #{v.$notification-item-info-color};
  --notification-item-warning-color: #{v.$notification-item-warning-color};
  --notification-item-error-color: #{v.$notification-item-error-color};
}
