@use 'colors';
@use 'variables' as v;

@use '~bootstrap/scss/bootstrap' with (
  // Colors
  $theme-colors: colors.$theme-colors,
  $colors: colors.$colors,
  $blue: colors.$blue,
  $green: colors.$green,
  $yellow: colors.$yellow,
  $purple: colors.$purple,
  $pink: colors.$pink,
  $red: colors.$red,
  $orange: colors.$orange,

  // Typography
  $font-size-base: v.$font-size-base,
  $font-family-sans-serif: v.$font-family-sans-serif,

  // Spacing
  $spacer: v.$spacer,
  $border-radius: v.$border-radius,

  // Shadows
  $box-shadow: v.$box-shadow,

  //Form controls
  $input-height: v.$input-height,
  $input-border-width: v.$input-border-width,
  $input-padding-y: v.$input-padding-y,
  $input-padding-x: v.$input-padding-x,
  $input-border-color: v.$input-border-color,
  $input-color: v.$input-color,
  $input-placeholder-color: v.$input-placeholder-color,
  $input-font-size: v.$input-font-size,
  $input-focus-border-color: v.$input-focus-border-color,
  $input-focus-box-shadow: v.$input-focus-box-shadow,
  $input-focus-color: v.$input-focus-color,
  $input-btn-font-size: v.$input-btn-font-size,

  // Form validation
  $form-feedback-font-size: 90%,

  // Dropdowns
  $dropdown-padding-y: v.$dropdown-padding-y,
  $dropdown-item-padding-y: v.$dropdown-item-padding-y,
  $dropdown-item-padding-x: v.$dropdown-item-padding-x,
  $dropdown-link-active-color: v.$dropdown-link-active-color,
  $dropdown-link-active-bg: v.$dropdown-link-active-bg,
  $dropdown-link-color: v.$dropdown-link-color,
  $dropdown-link-hover-bg: v.$dropdown-link-hover-bg,
);

// Error messages are not rendered on screen unless there is an error,
// so we always want them to display: block if rendered.
.invalid-feedback {
  display: block;
}

.custom-switch {
  padding-left: 0;

  .custom-control-label {
    display: block;

    &:before {
      left: initial;
      right: 0;
    }

    &:after {
      left: initial;
      right: v.subtract(v.$custom-switch-width, v.add(v.$custom-switch-indicator-size, v.$custom-control-indicator-border-width * 2));
    }
  }
}
