@use 'app-colors' as c;
@use 'app-variables' as v;

.pageLoaderContainer {
  pointer-events: none;
}

.pageLoaderBar {
  background: rgba(255, 255, 255, .55);
  height: 4px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
}
