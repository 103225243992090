@use 'app-colors' as c;
@use 'app-variables' as v;

.main {
  height: 100%;
}

.wrapper {
  padding: v.$spacer;
  height: 100%;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;

    .logo, .text, .action {
      // flex: 1;
    }

    .logo {
      width: 100%;
      .image {
        // width: 300px !important;
        height: auto;
      }
    }

    .text {
      margin: v.$spacer * 4 0;
      font-size: v.$font-size-base * 1.2;
    }

    .action {
      .button {
        font-size: v.$font-size-base;
        padding: v.$spacer * .8 v.$spacer * 2.5;
      }
    }
  }
}
